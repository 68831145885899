import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useUnassignedServiceRequestList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refUnassignedServiceRequestsTable = ref(null)

  // Table Handlers
  // Table Handlers
  const formTableColumns = [
    {
      key: 'serviceForm',
      label: '',
      sortable: false,
      tdClass: 'service-form-group',
      variant: 'secondary',
    },
    {
      key: 'stringID',
      label: 'id',
      sortable: true,
    },
    {
      key: 'workFlow',
      label: 'Request Details',
      sortable: true,
    },
    {
      key: 'deceased',
      label: 'Deceased Details',
      sortable: false,
    },
    {
      key: 'date',
      label: 'Service Date',
      sortable: true,
    },
    {
      key: 'location',
      label: 'Location & Time',
      sortable: true,
    },
    {
      key: 'serviceDescriptionValues',
      label: 'Service descriptions',
      sortable: false,
      thStyle: { width: '310px', minWidth: '310px' },
    },
    {
      key: 'remarks',
      label: 'remarks',
      sortable: false,
    },
    {
      key: 'agentName',
      label: 'Agent',
      sortable: true,
    },
    {
      key: 'customerName',
      label: 'Customer',
      sortable: true,
    },
    {
      key: 'assignees',
      label: 'Assigned To',
      sortable: true,
    },
    { key: 'paymentStatus', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]

  const requestTableColumns = [
    {
      key: 'stringID',
      label: 'id',
      sortable: true,
    },
    {
      key: 'workFlow',
      label: 'Request Details',
      sortable: true,
    },
    {
      key: 'deceased',
      label: 'Deceased Details',
      sortable: false,
    },
    {
      key: 'date',
      label: 'Service Date',
      sortable: true,
    },
    {
      key: 'location',
      label: 'Location & Time',
      sortable: true,
    },
    {
      key: 'serviceDescriptionValues',
      label: 'Service descriptions',
      sortable: false,
      thStyle: { width: '310px', minWidth: '310px' },
    },
    {
      key: 'remarks',
      label: 'remarks',
      sortable: false,
    },
    {
      key: 'agentName',
      label: 'Agent',
      sortable: true,
    },
    {
      key: 'customerName',
      label: 'Customer',
      sortable: true,
    },
    {
      key: 'assignees',
      label: 'Assigned To',
      sortable: true,
    },
    { key: 'paymentStatus', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalServiceRequests = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'stringID')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const groupByForm = ref(localStorage.getItem('groupByForm') === 'true')
  const statusFilter = ref([])
  if (Array.isArray(route.query.status)) {
    statusFilter.value = route.query.status
  } else if (route.query.status) {
    statusFilter.value = [route.query.status]
  }
  const toDeletedID = ref(null)
  const type = ref('unassigned')
  const serviceRequestData = ref([])
  const dueDateFilter = ref(route.query.dueDate || '')
  const serviceDateFilter = ref(route.query.serviceDate || '')
  const requestTypeFilter = ref([])
  if (Array.isArray(route.query.requestType)) {
    requestTypeFilter.value = route.query.requestType
  } else if (route.query.requestType) {
    requestTypeFilter.value = [route.query.requestType]
  }
  const assigneeFilter = ref([])
  if (Array.isArray(route.query.assignee)) {
    assigneeFilter.value = route.query.assignee
  } else if (route.query.assignee) {
    assigneeFilter.value = [route.query.assignee]
  }
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refUnassignedServiceRequestsTable.value ? refUnassignedServiceRequestsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalServiceRequests.value,
    }
  })

  const refetchData = () => {
    refUnassignedServiceRequestsTable.value.refresh()
  }

  watch([groupByForm, searchQuery, statusFilter, dueDateFilter, serviceDateFilter, requestTypeFilter, assigneeFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchServiceRequests = (ctx, callback) => {
    if (groupByForm.value) {
      store
        .dispatch('app-unassigned-service-requests/fetchGroupedServiceRequests', {
          search: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          status: statusFilter.value,
          dueDate: dueDateFilter.value,
          serviceDate: serviceDateFilter.value,
          type: type.value,
          requestType: requestTypeFilter.value,
          assignee: assigneeFilter.value,
        },
        { root: true })
        .then(response => {
          const { serviceRequests, total } = response.data
          serviceRequestData.value = serviceRequests
          callback(serviceRequests)
          totalServiceRequests.value = total
          if (!initialLoad) {
            router.replace({
              query: {
                search: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value,
                dueDate: dueDateFilter.value,
                serviceDate: serviceDateFilter.value,
                requestType: requestTypeFilter.value,
                assignee: assigneeFilter.value,
              },
            }).catch(() => {})
          }
          initialLoad = false
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      store
        .dispatch('app-unassigned-service-requests/fetchServiceRequests', {
          search: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          status: statusFilter.value,
          dueDate: dueDateFilter.value,
          serviceDate: serviceDateFilter.value,
          type: type.value,
          requestType: requestTypeFilter.value,
          assignee: assigneeFilter.value,
        },
        { root: true })
        .then(response => {
          const { serviceRequests, total } = response.data
          serviceRequestData.value = serviceRequests
          callback(serviceRequests)
          totalServiceRequests.value = total
          if (!initialLoad) {
            router.replace({
              query: {
                search: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value,
                dueDate: dueDateFilter.value,
                serviceDate: serviceDateFilter.value,
                requestType: requestTypeFilter.value,
                assignee: assigneeFilter.value,
              },
            }).catch(() => {})
          }
          initialLoad = false
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }

  const deleteServiceRequest = () => {
    store
      .dispatch('app-unassigned-service-requests/deleteServiceRequest', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveServiceRequestStatusVariant = status => {
    if (status === 'completed') return 'success'
    if (status === 'pending') return 'warning'
    if (status === 'awaiting approval') return 'danger'
    return 'primary'
  }

  return {
    fetchServiceRequests,
    formTableColumns,
    requestTableColumns,
    perPage,
    currentPage,
    totalServiceRequests,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUnassignedServiceRequestsTable,

    resolveServiceRequestStatusVariant,
    refetchData,

    toDeletedID,
    deleteServiceRequest,
    serviceRequestData,
    groupByForm,

    // Extra Filters
    statusFilter,
    dueDateFilter,
    serviceDateFilter,
    requestTypeFilter,
    assigneeFilter,
  }
}
